
import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';
import { withRedirect } from '@virtuslab/nfs-shared/src/services/routes';

import { ProjectDetailsRoutes } from '../../../config/paths';

const ProjectDetails = lazy(async () => import('.'));
const AboutProject = lazy(async () => import('../../organisms/AboutProject'));
const ProjectMembers = lazy(async () => import('../../organisms/ProjectMembers'));

const config: RoutesConfig<WrapRoutes<typeof ProjectDetailsRoutes>> = {
  paths: {
    [ProjectDetailsRoutes.DETAILS]: {
      render: withRedirect(ProjectDetails, {
        from: ProjectDetailsRoutes.DETAILS,
        to: ProjectDetailsRoutes.ABOUT,
      }),
    },
    [ProjectDetailsRoutes.ABOUT]: {
      render: AboutProject,
    },
    [ProjectDetailsRoutes.MEMBERS]: {
      render: ProjectMembers,
    },
  },
};

export default config;
