import type { ReactiveVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import first from 'lodash/first';
import merge from 'lodash/fp/merge';
import isNil from 'lodash/isNil';

import type { DrawerQueryVariables } from '@virtuslab/nfs-shared/src/schema/admin';
import type { ProjectAssignmentKeySpecifier, StrictTypedTypePolicies } from '@virtuslab/nfs-shared/src/schema/admin/clientHelpers';
import { isLiteral } from '@virtuslab/nfs-shared/src/services/guards';
import { typesafePolicies, isVariables, sharedPolicies } from '@virtuslab/nfs-shared/src/state';

import type { FlatDrawers, Drawer } from './models/Drawers';
import * as queries from './operations/queries';
import typeDefs from './schema';

const idAtKeyFields = (...keyFieldArgs: unknown[]): ('at' | 'id')[] => {
  // types are wrong, docs show what arguments will be passed here
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
  const object = first(keyFieldArgs);

  if (isLiteral(object)) {
    return 'at' in object && !isNil(object.at) ? ['id', 'at'] : ['id'];
  }

  return ['id'];
};

export type ReactiveVars = Readonly<{
  drawersVar: ReactiveVar<FlatDrawers>;
}>;

export const typePolicies = (
  { drawersVar }: ReactiveVars,
): StrictTypedTypePolicies => typesafePolicies<StrictTypedTypePolicies>()({
  ...sharedPolicies,
  Mutation: {
    keyFields: [],
    fields: {
      user: {
        merge,
      },
    },
  },
  Query: {
    keyFields: [],
    fields: {
      users: relayStylePagination(['filter', 'sort']),
      companies: relayStylePagination(['filter', 'sort']),
      contracts: relayStylePagination(['filter', 'sort']),
      contractsB2BSalaryDetails: relayStylePagination(['filter', 'sort', 'yearMonth']),
      contractsEmploymentSalaryDetails: relayStylePagination(['filter', 'sort', 'yearMonth']),
      projects: relayStylePagination(['filter', 'sort']),
      spaces: relayStylePagination(['filter', 'sort']),
      dashboard: { merge },
      drawer: {
        read(_, { variables }): Drawer | null {
          const drawers = drawersVar();

          if (isVariables<DrawerQueryVariables>(variables, 'id')) {
            return queries.drawer(drawers, variables);
          }

          return null;
        },
      },
      rootDrawer: {
        read: () => queries.rootDrawer(drawersVar()),
      },
      topDrawer: {
        read: () => queries.topDrawer(drawersVar()),
      },
    },
  },
  User: {
    keyFields: idAtKeyFields,
  },
  Contract: {
    keyFields: idAtKeyFields,
  },
  Company: {
    keyFields: idAtKeyFields,
  },
  ClientContract: {
    keyFields: idAtKeyFields,
    merge: true,
  },
  ClientPosition: {
    keyFields: idAtKeyFields,
  },
  DirectReporting: {
    keyFields: false,
    merge: true,
  },
  AssignedVariant: {
    keyFields: idAtKeyFields,
    merge: true,
  },
  BenefitVariant: {
    keyFields: idAtKeyFields,
  },
  Address: {
    keyFields: false,
    merge: true,
  },
  Space: {
    keyFields: ['id'],
    fields: {
      members: relayStylePagination(['filter', 'sort']),
    },
  },
  B2BContractSalaryDetails: {
    keyFields: ['contractId', 'yearMonth'],
    merge: true,
  },
  EmploymentContractSalaryDetails: {
    keyFields: ['contractId', 'yearMonth'],
    merge: true,
  },
  ContractSalaryDetailsAdditionalData: {
    keyFields: false,
    merge: true,
  },
  TimeBasedCurrency: {
    keyFields: false,
    merge: true,
  },
  TimeBasedVatType: {
    keyFields: false,
    merge: true,
  },
  TimeBasedOptClearableOnCallRate: {
    keyFields: false,
    merge: true,
  },
  TimeBasedOptClearableNetGross: {
    keyFields: false,
    merge: true,
  },
  TimeBasedRate: {
    keyFields: false,
    merge: true,
  },
  Rate: {
    keyFields: false,
    merge: true,
  },
  Bonuses: {
    keyFields: false,
    merge: true,
  },
  Money: {
    keyFields: false,
    merge: true,
  },
  TimeReport: {
    keyFields: false,
    merge: true,
  },
  EmploymentTimeReportStats: {
    keyFields: false,
    merge: true,
  },
  B2BTimeReportStats: {
    keyFields: false,
    merge: true,
  },
  TimeBasedBenefitBuyerType: {
    keyFields: false,
    merge: true,
  },
  TimeBasedBenefitVariant: {
    keyFields: false,
    merge: true,
  },
  TimeBasedSpace: {
    keyFields: false,
    merge: true,
  },
  TimeBasedOptClearableBenefitVariantRate: {
    keyFields: false,
    merge: true,
  },
  ProjectAssignment: {
    keyFields: [
      'user',
      // apollo's types don't handle what they have in their docs
      // https://www.apollographql.com/docs/react/caching/cache-configuration/
      ['id'] as unknown as ProjectAssignmentKeySpecifier,
      'project',
      ['id'] as unknown as ProjectAssignmentKeySpecifier,
    ],
  },
});

export { typeDefs };
