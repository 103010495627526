import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { ReportsRoutes } from '../../../config/paths';

const ReportsPage = lazy(async () => import('.'));
const ReportsRemindersPage = lazy(async () => import('../ReportsReminders'));

const config: RoutesConfig<WrapRoutes<typeof ReportsRoutes>> = {
  paths: {
    [ReportsRoutes.REPORTS_REMINDERS]: {
      render: ReportsRemindersPage,
    },
    [ReportsRoutes.REPORTS]: {
      render: ReportsPage,
    },
  },
};

export default config;
