import type { ConditionalPick } from 'type-fest';

import type {
  B2BContractType,
  CompanyType,
  ContractStatus,
  CompanyField,
  EmploymentContractType,
  FilterContractType,
  IdType,
  ProgressionLevel,
  Settlement,
  SortCompany,
  UserField,
  ContractField,
  ClientContractField,
  DeductibleCostsType,
  OnCallMultiplier,
  PaymentStatus,
  BenefitBillingPeriod,
  BenefitInvoiceCategory,
  ContractType,
  ContractDocumentTemplateLanguage,
  Language,
  Quarter,
} from '@virtuslab/nfs-shared/src/schema/admin';
import type { InterpolatedVariables } from '@virtuslab/nfs-shared/src/services/i18n';
import type { PluralBuildingBlocksToMessages, SharedEnumMessages } from '@virtuslab/nfs-shared/src/services/i18n/messages';
import {
  sharedPluralBuildingBlocks, sharedPluralMessages, makePluralMessages, sharedMessages,
} from '@virtuslab/nfs-shared/src/services/i18n/messages';

import type { IsStudent } from '../additionalTypes';
import type { PendingStateDescriptionType } from '../salaries';

export interface EnumMessages extends SharedEnumMessages {
  companyField: CompanyField;
  userField: UserField;
  contractField: ContractField;
  clientContractField: ClientContractField;
  companyType: CompanyType;
  contractStatus: ContractStatus;
  employmentContractType: EmploymentContractType;
  idType: IdType;
  progressionLevel: ProgressionLevel;
  settlement: Settlement;
  sortCompany: SortCompany;
  b2bContractType: B2BContractType;
  filterContractType: FilterContractType;
  deductibleCostType: DeductibleCostsType;
  isStudent: IsStudent;
  pendingStateDescription: PendingStateDescriptionType;
  pendingStateDescriptionPlural: PendingStateDescriptionType;
  onCallMultiplier: OnCallMultiplier;
  paymentStatus: PaymentStatus;
  benefitBillingPeriod: BenefitBillingPeriod;
  benefitInvoiceCategory: BenefitInvoiceCategory;
  contractType: ContractType;
  contractDocumentTemplateLanguage: ContractDocumentTemplateLanguage;
  language: Language;
  quarter: Quarter;
}

export const enumMessage = <
  EnumKey extends keyof EnumMessages,
  EnumType extends EnumMessages[EnumKey],
>(enumName: EnumKey, value: EnumType): `${EnumKey}-${EnumType}` => `${enumName}-${value}` as const;

export type Messages = {
  [key in keyof EnumMessages as `${key}-${EnumMessages[key]}`]: undefined
} & {
  [key in PluralBuildingBlocksToMessages<typeof pluralBuildingBlocks>]: InterpolatedVariables<key>
} & {
  [key in PluralMessages]: InterpolatedVariables<key>
} & {
  [key in typeof messages[number]]: InterpolatedVariables<key>
};

export type MessageKey = keyof Messages;
export type PlainMessageKey = keyof ConditionalPick<Messages, undefined>;
export type PluralMessages = keyof (typeof pluralMessages);

export const pluralBuildingBlocks = {
  ...sharedPluralBuildingBlocks,
  ...makePluralMessages({}),
};

export const pluralMessages = {
  ...sharedPluralMessages,
  ...makePluralMessages({
    '{{count}}-digits number': {
      one: '{{count}}-digit number',
    },
    '{{count}} leave days': {
      one: '{{count}} leave day',
    },
    '{{count}} transfers in total': {
      one: '{{count}} transfer in total',
    },
    'There are no entries in {{count}} working days': {
      one: 'There are no entries in {{count}} working day',
    },
    '{{count}} selected items': {
      one: '{{count}} selected item',
    },
    '{{count}} months ago': {
      one: '{{count}} month ago',
    },
    '{{count}} project members': {
      one: '{{count}} project member',
    },
  }),
};

const messages = [
  ...sharedMessages,
  '{{username}} - My profile',
  '{{moneyPerHour}} (Overtime)',
  'h',
  'Add new person',
  'Name',
  'Surname',
  'E-mail (login)',
  'Drafts & No contracts',
  'List of people without contracts or with drafts',
  'Group of people with name starting with {{groupTitle}}',
  'Group of people with surname starting with {{groupTitle}}',
  'Group of people which were created on {{groupTitle}}',
  'Entry for person: {{personName}}',
  'Close',
  '{{name}} is required',
  'Contracts & Benefits',
  'Documents',
  'Friends & Family',
  'Edit profile',
  'Account activity',
  'Add comment or note…',
  'Changed {{name}} status to: {{status}}',
  'Pin this activity',
  'Unpin this activity',
  'Activity by {{name}}',
  'Open an emoji menu',
  'Write a new activity',
  'Provide a basic or an overtime rate',
  'Basic rate',
  'Overtime rate',
  'Payment details',
  'Currency',
  'Effective date',
  'Effective from {{date}}',
  'Edit payment details',
  'Add payment details',
  'per hour',
  'per month',
  'Overtime rate (net)',
  'Overtime rate per hour',
  'Hourly rates',
  'Currency',
  'Edit hourly rates',
  'Add hourly rates',
  'Basic rate (net)',
  'Overtime rate (net)',
  'Bonus rate',
  'Bonus on {{date}}',
  'Category',
  'Fill in only the fields that are to be updated',
  'Concluded on {{date}}',
  'Concluded on <hidden>hidden</hidden>',
  'No conclusion date',
  'No start date',
  'No contract time',
  'No promisee selected. Edit draft to select a promisee.',
  'No VAT rate',
  'open ended',
  'Promisee',
  'Promisor',
  'List of contracts',
  'List entry for {{contractName}}',
  'Hide details for {{contractName}}',
  'Show details for {{contractName}}',
  'Edit payment details for this contract',
  'Add contract draft',
  'Create {{contractType}} contract draft',
  'Edit {{contractType}} contract draft',
  'Create {{contractType}} contract draft form',
  'Edit {{contractType}} contract draft form',
  'Drawer with a form for creating {{contractType}}',
  'Drawer with a form for adding a new company',
  'Drawer with a form for adding a new person',
  'Contract name',
  'Promisor',
  'Promisee',
  'Search for {{thing}}…',
  'Basic info',
  'No search results',
  'Select promisor',
  'Add new',
  'Contract time',
  'Start date',
  'No start date',
  'No contract time',
  'Pick date',
  '{{name}} (optional)',
  '{{name}} (opt.)',
  '{{name}} (gross)',
  '{{name}} (net)',
  'End date',
  'Additional data (User profile)',
  'Identity document',
  'Document type',
  'Select document type',
  'ID number',
  'PESEL',
  'Contract details',
  'Scope of work',
  'Select default or add custom',
  'Use enter to add a new line',
  'Don\'t have a PESEL number',
  'Start date must precede the end date',
  'Must be a valid pesel',
  'Promisee and promisor must be different',
  'Save as draft',
  'Save as signed',
  'Note',
  'Draft control buttons',
  '{{contractType}} Contract',
  'B2B',
  'Select country',
  'Select currency',
  'Select country to get the proper add company form',
  'Country',
  'Go to the list of people',
  'Go to the list of companies',
  'Go to {{entity}}\'s details',
  'Edit progression',
  'Set progression',
  'Autocomplete for {{fieldName}}',
  'Add new company',
  'Edit payment details for this contract',
  'No changes made to the contract rates',
  'Drawer with a form for editing payment details of a contract',
  'Provide a basic or an overtime rate',
  'Test',
  'People',
  'Companies',
  'Benefits',
  'Reports',
  'Settings',
  'Management',
  'Dashboard',
  'Logout',
  'Accounts settings',
  'Add bonus',
  'Edit bonus',
  'Bonus',
  'Add bonus for this contract',
  'Bonus description',
  'Bank account',
  'Edit bank account',
  'Edit bank account for this contract',
  'Short description',
  'Add company',
  'NIP',
  'Update from Register',
  'Company name',
  'Street address',
  'Postal code',
  'City or location',
  'REGON',
  'KRS',
  'Mailing address',
  'Set different mailing address',
  'VAT tax',
  'VAT payer',
  'Bank accounts',
  'Account number (IBAN)',
  'Account number',
  'BIC/SWIFT',
  'Short description',
  'Add bank account',
  'Region',
  'Register details',
  'Country prefix will be automatically added for VAT EU payers',
  'Company Type',
  'This field must be a valid REGON',
  'This field must be a valid KRS number',
  'This field must be a valid VAT identification number',
  'EU VAT payer',
  'List of companies',
  'Entry for company: {{companyName}}',
  'VAT Payer',
  'VAT EU',
  'Set progression',
  'Position',
  'Annual leave',
  'Days left for the current year',
  'Ongoing higher education',
  'NIP',
  'Birth date',
  'Additional rates',
  'On call rates',
  'Day rate',
  'Night rate',
  'Special rate',
  'None',
  'VAT rate',
  'On call rates (net)',
  'Working time',
  'Copyright costs',
  'PPK',
  'PPK in %',
  'Copyright costs',
  'Copyright costs in %',
  'Annual leave entitlement',
  'Select date',
  'Date from when 26-days leave is entitled',
  'User profile data will be updated along with draft state',
  'Full-time',
  'Part-time',
  'Residence address (User profile)',
  'Must be a valid NIP',
  'Student under-26 years old',
  'Pick date range',
  'Tax deductible costs',
  'VAT rate',
  'Select VAT rate',
  'Most commonly used',
  'Other',
  'Exempt from VAT',
  'Copyright cost & PPK',
  'Select {{name}}',
  'No residence address',
  'No mailing address',
  'No private e-mail address',
  'No mobile phone number',
  'Contracts',
  'List of contracts for {{groupTitle}}',
  'List of contract drafts',
  'Drafts',
  'Entry for contract: {{contractName}}',
  'Change filters or clear them all',
  'Clear all filters',
  'Progression',
  'Office',
  'Engineering',
  'Non-engineering',
  'Clear all',
  'Create date',
  'Create date (default)',
  'This month',
  'Last month',
  'Specific date',
  'Date range',
  'Office',
  'Surname A-Z',
  'Surname Z-A',
  'Name A-Z',
  'Name Z-A',
  'Sort by',
  'Sort by {{type}}',
  'Jump to',
  'Bank account',
  'Add bank account',
  'Edit bank account for this contract',
  'Account number (IBAN)',
  'Account number',
  'BIC',
  'SWIFT',
  'Short description',
  'Verified in the White list',
  'Not listed in The White list',
  'Eg. 1/4',
  'Engineering progression',
  'Non-engineering progression',
  '(optional)',
  '(gross)',
  '(net)',
  '(no end date)',
  'No bank account',
  'Changes will be applied on the selected date',
  'You\'ve scheduled a change before',
  'Pick a date to edit or add a new one',
  'Promisee must be selected before adding a bank account to this contract',
  'The {{entityType}} <strong>{{entityName}}</strong> was removed',
  'The {{entityType}} was removed',
  '<strong>{{entityName}}</strong> was removed',
  'The {{entityType}} <strong>{{entityName}}</strong> was created',
  'The {{entityType}} was created',
  'Scheduled by {{author}} on {{date}}',
  'User',
  'Company',
  'Contract',
  'NFS',
  'Save',
  'Last week',
  'This week',
  'Today',
  'Start date (default)',
  'Conclusion date',
  'Type',
  'Contract',
  'Subcontract',
  'Open-ended contracts',
  'Yes',
  'No',
  '{{days}} days (first work)',
  'Pick seniority change date (opt.)',
  'VAT',
  'No VAT',
  'Name A-Z (default)',
  'Search by name or NIP',
  'Team',
  'Projects',
  'Pinned',
  'Cancel',
  'Edit',
  'Edit bonuses for this contract',
  'There are no contracts yet',
  'There are no people yet',
  'There are no companies yet',
  'You need to add at least one person with a contract or a contract draft',
  'There is nothing here yet',
  'Profile actions',
  'No address',
  'No additional info',
  '{{count}} of {{totalCount}}',
  'Delete {{contractName}} contract draft?',
  'Delete contract draft',
  'Not defined',
  'Future activities',
  'Show next month',
  'Show previous month',
  'Client contracts',
  'There are no client contracts yet',
  'Add a client contract',
  'Add client contract',
  'Name and address',
  'VAT Tax',
  'Bank accounts',
  'Register details',
  'Company actions',
  'Not verified in {{register}}',
  'Verified in {{register}}',
  'Verified in {{register}} on {{date}}',
  'Not listed in {{register}} on {{date}}',
  'VAT register',
  'VIES',
  'Currency and VAT rate',
  'Positions and rates (net)',
  'Add rate card',
  'Edit rate card',
  'Drawer with a form for adding a new client contract',
  'Contract id',
  'Client contract',
  'Terms of payments',
  'Describe terms of payment…',
  'Describe terms and conditions…',
  'Terms and conditions',
  'Notes',
  'Describe additional info…',
  'Supported additional info…',
  'Supported with factoring',
  'Edit client contract',
  'List of client contracts',
  'Factoring',
  'Edit this contract',
  'Client',
  'No terms set',
  'Updating…',
  'Add a position',
  'Set currency and VAT rate first',
  'i.e. {{thing}}',
  'Overtime',
  'Add on call rates',
  'Remove this position',
  'Fields for position no. {{number}}',
  'rate card',
  'Rate',
  'Remove on call rates',
  'Active',
  'Inactive',
  'Marked fields are required',
  'Add next position',
  'Edit company details',
  'Edit profile details',
  'Terminate contract',
  'Drawer with a contract details',
  'Drawer with a form for editing profile details',
  'Terminate {{contractName}} contract?',
  'Sorry, we were unable to check your {{thing}}',
  'Polish VAT payer status',
  'VAT payer status',
  'EU VAT payer status',
  'Pinned by {{pinnedBy}}',
  'you',
  'default',
  'Set as default',
  'Add new project',
  'There are no projects yet',
  'Search by name, clients or team details',
  'Project name',
  'Select a client first',
  'Entry for project: {{project}}',
  'Manage team members',
  'Add a project member',
  'People and positions',
  'Search for a team member',
  'Done',
  'Team member',
  'Add',
  'Remove',
  'Select a position',
  'Rate card has no VAT rate or currency set or no positions added',
  'Edit project',
  'Set as disabled',
  'Drawer with a form for editing company details',
  '{{thing}} and {{other}}',
  '(was {{previousState}})',
  '(was not set before)',
  'Bank account actions',
  'NIP without prefix',
  'Not verified in the White list',
  'Address',
  'Additional data',
  'Office or remote work',
  'Additional e-mail',
  'Phone',
  'Space',
  'Revision date',
  'It\'s time to check for an update',
  'It seems to be checked for now',
  'Filter by',
  'Pick a date to add a new time based change',
  'Starred',
  'Client A-Z',
  'Client Z-A',
  'Client A-Z (default)',
  'No tax number',
  'Members',
  'Add to favorites',
  'Remove from favorites',
  'Project members for {{projectName}}',
  'Client contract details',
  'Person details',
  'Contract details',
  'Contract draft details',
  'Next medical examination',
  'Progression level',
  'Continue and save draft',
  '{{person}}\'s profile will also be updated by saving this draft',
  'Following changes will be made',
  '{{field}} from <strong>{{previousValue}}</strong> to <strong>{{newValue}}</strong>',
  'Separate mailing address',
  'none',
  'Senioriy change date',
  'Revision date',
  'Studies',
  'Loading',
  'Register info',
  'Company details',
  'Go back to the form',
  'You have unsaved changes',
  'Please discard or confirm any unsaved changes',
  'Annual leave settlement',
  'Days to be settled',
  'Leave balance will be automatically updated',
  'Position name',
  'At least 1 position is required',
  'Initial annual leave (days)',
  'Spaces',
  'Add new space',
  'Search by name, space owner or member',
  'There are no spaces yet',
  'Company type',
  'Drawer with a form for editing company type',
  'You can select more then 1 type',
  '• {{companyType}} – applies for any company which represents a team member, often used for a sole proprietorship, available for B2B contracts',
  '• {{companyType}} – allows to create a client contract along with a rate card which are needed to create a project',
  '• {{companyType}} – applies for any company which supplies a Group company, it should have cost invoices',
  '• {{companyType}} – applies for companies which creates VirtusLab Group, it\'s important for internal projects and overall reporting',
  '• {{companyType}} — VirtusLab group companies that form the capital group',
  'Need more accounts?',
  'Edit promisee',
  'This field is required to sign a contract',
  'You can\'t create a project team',
  'Go to Rate card',
  'Rate card has no positions added (Client contract {{contractName}})',
  'Rate card has no currency set (Client contract {{contractName}})',
  'Discard unsaved changes?',
  'Discard',
  'Back to editing',
  'Next medical examination date',
  '(NE)',
  'Search for promisee',
  'Failed to load data from registry',
  'Contract time: {{date}}',
  'Terminates on {{date}}',
  'Terminated on {{date}}',
  'Draft',
  'Entry for space: {{space}}',
  'by {{spaceOwner}}',
  'Space members for {{spaceName}}',
  'Space details',
  'Edit space',
  'There are no members yet',
  'Go to the list of spaces',
  'Space name',
  'Space owner',
  'Search user',
  'was {{thing}}',
  'Edit space',
  'No positions added',
  'Newly added position(s) will be affected by the previously scheduled currency change. Review them',
  'Got it, close',
  'No or not applicable',
  'Not applicable',
  '(man-day)',
  'Add benefit',
  'Drawer with a form for editing benefits',
  'Edit benefit',
  'VAT ID',
  'VAT EU',
  'No register connected. Verify manually',
  'No VAT information is available for companies located in {{country}}',
  'Export CSV file',
  'From',
  'To',
  'Archived from',
  'Contract draft',
  'Bank Account',
  'Member',
  'User',
  'Contract',
  'Company',
  'Rate Card',
  'Subbenefits',
  'Default values have been changed',
  'Revert',
  'Buyer',
  'Refunded',
  'Activity',
  'Benefit',
  'Benefit variant',
  'Future change',
  'Comment',
  'Middle name',
  'Tax office',
  'Create draft',
  'UoP',
  'UZ',
  'UoD',
  'Save and generate in Google Docs',
  'Create contract',
  'Contract draft actions',
  'Concluded on',
  'Representative',
  'Place of signing',
  'Contract language',
  'City',
  'Add next contract',
  'Go to Client contract',
  'No Rate card has been added for the Client contract {{contractName}}',
  'Taxed',
  'Month of awarding',
  'The bonus will be paid together with the salary for selected month.',
  'Details',
  'Planned',
  'See all bonuses',
  'Awarded {{date}}',
  'Recent bonuses',
  'Bonuses',
  'Edit bonus {{name}} for {{date}}',
  'Delete bonus {{name}} for {{date}}',
  'Delete recurring bonus {{name}} ({{dateRange}})',
  'All bonuses',
  'Drawer with all bonuses',
  'Bonuses for the year {{year}}',
  'Subbenefits',
  'Default values have been changed',
  'Revert',
  'Variant',
  'Buyer',
  'Not refunded',
  'Entry for transfer for {{contract}} for {{date}}',
  '{{money}} to pay',
  '{{money}} gross',
  '{{money}} net',
  'Salaries',
  'No leave reported',
  'Pending',
  'Ready',
  'No transfer amount',
  'Commercial',
  'Internal',
  'Add new project (internal)',
  'Group',
  'Internal project',
  '(internal)',
  'Search',
  'Open in Google Docs',
  'Regenerate file',
  'Represented by {{representative}}',
  'Represented by <hidden>hidden</hidden>',
  'Contract file',
  'The current contract file will be overridden.',
  'Regenerate existing contract file?',
  'Regenerate',
  'Generate in Google Docs',
  'VAT status',
  'Salaries',
  'Go to salaries for {{date}}',
  '{{count}} done',
  '{{count}} ready',
  '{{count}} pending',
  '{{money}} in total',
  'Missing {{missingElements}}',
  'There are no salaries for {{year}}',
  'B2B Contract',
  'Employment & civil law contracts',
  'Import',
  'Employment & civil contracts',
  'B2B contracts',
  'Salary details',
  'Drawer with salary details for {{month}}',
  'Payment info',
  'Payroll input',
  'Payroll details',
  'Transfer amount',
  'Tax prepayment',
  'ZUS contribution',
  'PPK amount',
  'Transfer details',
  'Sender',
  'Receiver',
  'Title',
  'Amount',
  'Generate payroll',
  'Open contract details',
  'Open user profile',
  'No planned salaries yet',
  'Status (default)',
  'Surname (A-Z)',
  'Surname (Z-A)',
  'Transfer amount (1-9)',
  'Transfer amount (9-1)',
  'Transfer date (1-9)',
  'Transfer date (9-1)',
  'Sender',
  'Contract type',
  'Time report',
  'The White list is not verified correctly',
  'Drawer with a form for editing a client contract',
  'Time report',
  'Reported time',
  'Work',
  'On call',
  'Leave',
  'Unpaid',
  'Contract info',
  'Contract type',
  'Payout',
  'Standard',
  'Ref. benefits',
  'There are no entries on {{days}} {{month}}',
  'Open salary details',
  'Open benefits details',
  'Referral',
  'Appreciation',
  'Language',
  'Student status',
  'Annual',
  'Special',
  'Sick leave',
  'Flextime',
  '(expires on {{date}})',
  'No leave reported',
  'Yes ({{number}}%)',
  '({{minutes}})',
  'Office package',
  'Select all',
  'Deselect',
  'Mark as done',
  'Unmark as done',
  'Generate payroll',
  'Export payroll input',
  'Transfers',
  'File',
  '{{from}} - {{to}}',
  '{{from}} - {{to}}, {{break}} break',
  'No time set',
  'No end time set',
  'Open time report',
  'Salaries entry actions',
  'Unmark as done',
  'Mark as done',
  'Done status',
  'Invoice delivery status',
  'ZUS',
  'Projects & Activities',
  'Edit user\'s projects',
  '{{start}} - no end date',
  'No progression level',
  'Drawer with a form for managing user\'s projects',
  'Assign new project',
  'Add project',
  'Search for a project',
  'planned',
  'Add position',
  'To assign next position please select the end date of the last one.',
  'This position is going to end on {{date}}',
  'This position is planned and it will be open from {{date}}',
  'This position is planned and it will be open from {{startDate}} to {{endDate}}',
  'Go to the list of salaries',
  'Select bank account',
  'Bank account changed?',
  'Promisor has no bank accounts assigned',
  'Promisee has no bank accounts assigned',
  'The file is ready',
  'Open',
  'There was an error while downloading the file.',
  '<a href="$2">link</a>',
  'a new picture ( {{link}} )',
  'Time reports',
  '{{contract}} contract from {{date}}',
  '{{contract}} holiday summary',
  'Go to the Time Report',
  'Expand / hide {{contractName}}',
  'Total rate',
  'Benefits costs',
  'To discard the Benefits cost change, modify the Benefits.',
  'Discard planned changes?',
  'Only planned changes to Payment details will be discarded. To discard the Benefits cost change, modify the Benefits.',
  'Warning',
  'Drawer with a form for managing contract bank accounts',
  'Drawer with a form for managing company bank account',
  'NIP: {{nip}}',
  'Show account activity',
  'Hide account activity',
  'Applied',
  'Open all reports',
  'Client invoicing report',
  'Summary of working time and rates for the selected client contract and period',
  'Benefit invoices',
  'Summary of benefit data for invoicing employees',
  'Project billables report',
  'Project billable hours multiplied by the rate card value as a function of time',
  'Open report',
  'Position ends on {{date}}',
  'Reported worktime (1-9)',
  'Reported worktime (9-1)',
  'Missing data',
  'User with this email already exists',
  'Company with this NIP already exists',
  'Profile',
  'Profile details',
  'Work e-mail',
  'Private e-mail',
  'Private phone number',
  'Residence address',
  'Effective from {{date}}',
  'Google Sheets file',
  'Select project',
  'Generate report',
  'Regenerate report',
  'Regenerate report?',
  'Regenerating a report will overwrite the current report file in Google Docs.',
  'Report',
  'Select month',
  'month',
  'You have selected items with different statuses, but payroll will be generated only for those marked as ready or done.',
  'Selected items might contain different statuses, but payroll will be generated only for those marked as ready or done.',
  'Generate payroll for ready and done items?',
  'No transfers with a status ready or done were selected',
  'Generate',
  'You cannot add a new entry for today because user has no active contract.',
  'Select period',
  'Select date range',
  'period',
  'Select client contract',
  'client contract',
  'Last rate change',
  'Progression & type',
  'Rate change value',
  'Basic rate per {{settlement}}',
  'Basic rate per hidden</hidden>',
  'The employee hasn\'t gotten any rate change for the last {{number}} months.',
  'hour',
  'month',
  'Chart',
  'List',
  'Rate change',
  'effective date',
  'Change value',
  'percent',
  'New rate: {{value}}',
  'New rate',
  'day',
  'Invoice preview',
  'Project assignment',
  'Change positions and rates only based on the latest version of the <strong>contract</strong> or <strong>annex</strong>',
  'Change rates only based on the latest version of the <strong>contract</strong> or <strong>annex</strong>',
  'Editing rate card',
  'Please remember that the data in the system is a representation of the real contract.',
  'By changing the data in the system, you do not change the contract itself.',
  'Missing {{time}} of working time',
  'Transfers summary',
  'Transfers summary for {{month}}',
  'All currencies included',
  'Expand / hide {{contracts}}',
  'Total {{currency}}',
  '+ overtime pay for {{time}}',
  'To be calculated by accountant',
  'Preview in Google Docs',
  'Preview contract',
  'Preview',
  'Delete planned bonus?',
  'Flag',
  'None',
  'Blue',
  'Green',
  'Red',
  'Orange',
  'Teal',
  'Purple',
  'Magenta',
  'Yellow',
  'Grey',
  'Black',
  'In contract as: {{data}}',
  'No promisor selected.',
  'To be determined',
  'No access to Time report',
  'The user has no signed contract in the system. Contact the user\'s Space Owner - {{spaceOwner}} ({{email}}).',
  'Payroll not generated',
  'VAT payers are not on the White list',
  'The transfer cannot be ordered until the promisee account is on the White list. Therefore, ready items have been reverted to the pending state. Please inform employees about the need for verification.',
  'You can try to generate payroll for non-whitelisted VAT payers from tomorrow as they will be reverted to ready state.',
  'Payroll partially generated',
  'The transfer cannot be ordered until the promisee account is on the White list. Therefore, some of the ready items have been reverted to the pending state. Please inform employees about the need for verification.',
  'Payroll succesfully generated',
  'Payroll cannot be generated for the current month.',
  'Archived',
  'Active',
  'To be archived',
  'Ends on {{date}}',
  'There was a problem loading this data. Please try again later.',
  'The new employee\'s leave entitlement in the first calendar year of employment. Calculated based on annual leave entitlement and the current date.',
  'In exceptional circumstances it may be changed by accounting team. The change affects the user leave balance.',
  'Family name',
  'Data for business trips',
  'Current employee data needed to organize or settle business trips',
  'Business trips',
  'Export of current employee data for business trips',
  'Expected end date',
  'This date is for informative purpose only, the contract won\'t terminate after this date.',
  'Termination date',
  'You can\'t undo this action',
  'Valid from {{date}} ',
  ' to ',
  'This contract should be terminated or extended until 2022.04.28.',
  'Extend expected end date',
  'This contract is scheduled for termination on {{date}}',
  '(expected end date: {{date}})',
  'This contract should be terminated or extended until {{date}}.',
  'Open-ended',
  'Contract already has termination date',
  'Valid from {{startDate}} to <box><alert>{{endDate}}</alert></box>',
  'Nationality',
  'UoP holiday summary',
  'Summary of annual leave for all employees',
  'Select year',
  'Products',
  'Settlements',
  'Add new benefit',
  'Add alt benefit',
  'Add alt sub-benefit',
  'Add variant',
  '(open-ended)',
  'Add sub-benefit',
  'Office {{office}}, room {{room}}, desk {{desk}}',
  'Office {{office}}, room {{room}}',
  'Office {{office}}',
  'Product ID',
  'Description',
  'Price',
  'Contract Type',
  'Billing period',
  'Promisee rate (net)',
  'Promisor rate (net)',
  'Additional people',
  'Accountancy settings',
  'Buyer',
  'Invoice category',
  'Adults',
  'Kids',
  'Mean of production',
  'Set as default but changeable value',
  'Period of validity',
  'Benefit name',
  'It is impossible to check at the moment. Government API calls exceeded the rate limit. Please try again later.',
  'Edit variant',
  'Valid from {{date}} (open-ended)',
  'Time reports summary for each project in the selected period',
  'CSV file',
  'All projects',
  'Refresh White list status',
  'White list statuses',
  'White list status',
  'on {{date}}',
  'Not checked yet',
  'Last update: {{date}}',
  'VAT rate is not changeable for the signed contract',
  'Starts on {{date}}',
  'Changes made in <strong>{{entity}}</strong>',
  'Changes made in {{entity}}',
  'Changes scheduled for <strong>{{entity}}</strong>',
  'Changes scheduled for {{entity}}',
  'Changes cancelled for <strong>{{entity}}</strong>',
  'Changes cancelled for {{entity}}',
  'Changes modified for <strong>{{entity}}</strong>',
  'Changes modified for {{entity}}',
  '{{fieldName}}: <strong>{{value}}</strong> → <strong>{{newValue}}</strong>',
  'User profile',
  'User profile of {{person}}',
  'Company details of {{company}}',
  'Add new sub-benefit',
  'Editing terminated contract disabled',
  'Effective date: <strong>{{date}}</strong>',
  'Check White list status and generate payroll for whitelisted items',
  'Verified on the White list today',
  'White list will be checked automatically when generating the payroll',
  'The White list is not verified correctly',
  'White list status will be updated when re-generating the payroll',
  'Promisee bank account {{iban}}',
  'Promisor bank account {{iban}}',
  'Drawer with a form for managing contract\'s payment details',
  'Toggle overtime rate',
  'Drawer with a form for creating a project',
  'Drawer with forms for managing project members',
  'Form for the project member {{member}}',
  'Form for a new project member',
  'Summaries of transfers for {{date}}',
  'Transfers for {{date}}',
  'B2B transfers for {{date}}',
  'Employment transfers for {{date}}',
  'Toggle selection of transfer for {{contract}} for {{date}}',
  'Select all transfers',
  'Deselect all transfers',
  'Contract template',
  'Referred person',
  'Person',
  'Search for a person',
  'Award date',
  'Employee referral bonus is awarded 3 months from the start date.',
  'The Month of awarding will adjust automatically based on the award date.',
  'Quarter',
  'Q{{number}}',
  'This employee has already granted 2 language learning bonuses for the quarter indicated.',
  'This employee already has an {{language}} language learning bonus granted for the quarter indicated.',
  'This bonus can only be awarded once',
  'This bonus has already been paid out to this employee.',
  'Takes {{amount}} from training package to a total of {{total}}.',
  'Invalid',
  'Referred person’s contract was terminated before 3 months, so the bonus is not due.',
  'Contract to which the bonus is assigned to was terminated',
  'Only one language learning bonus with given quarter/language pairing can exist in a given year',
  'Referred person\'s contract was terminated.',
  'Edit sub-benefit',
  'Edit price',
  'Changes to Price section will be applied on the selected date',
  'Generate a file with transfer data',
  'Can\'t generate payroll for a pending transfer',
  'First name and surname can only be updated through person\'s google profile',
  'Client has no contracts',
  'User has no contracts',
  'Pending transfers can\'t be marked as done',
  'Only done transfers can be unmarked as done',
  'Tax refund',
  'Not verified in the White list or it is impossible to check at the moment',
  'For current status, check the promisee\'s company',
  'Discard planned change?',
  'Register',
  'Request failed',
  'User has no active contract',
  'Basic financial report',
  'Financial report for the entire company, broken down by employees',
  'Space & progression',
  'To change <box><strong>Space</strong></box> allocation, go to <box><space>Projects & Activities</space>.</box> To change <box><strong>Progression level</strong>,</box> go to <box><progression>Progression</progression>.</box>',
  'To edit or discard the <box><strong>Space</strong></box> change, go to <box><space>Projects & Activities</space></box>.',
  'To edit or discard the <box><strong>Progression level</strong></box> change, go to <box><space>Progression</space></box>.',
  'Project leader',
  'The project has no leader yet.',
  'To assign one, mark one of the team members as a project leader.',
  'Add a project leader',
  'Project leader',
  'no end date',
  'The current project leader will be in this position until {{date}}. You can plan another team member for that position after that date.',
  'To assign the next project leader or unassign the current one, please select the end date.',
  'Form for the project leader {{leader}}',
  'Form for a new project leader',
  'Search for a person',
  'Team leader',
  'No template for {{contractType}}',
  'Base: {{amount}}',
  'Benefits: {{amount}}',
  'Total: {{amount}}',
  'to {{value}}',
  'Total gross salary',
  'Total transfer amount',
  'Total tax prepayment',
  'Total tax refund',
  'Total ZUS contribution',
  'Total PPK amount',
  '(to: {{date}})',
  '‌Cannot close contract due to time report entries after the termination date',
  'Product name',
  'Set new rate & progression level',
  'History',
  'N/A',
  'Drawer with a form for setting new rate and progression level',
  'Unavailable',
  'Variant doesn\'t match the contract\'s settlement',
  'Benefit doesn\'t match the contract\'s settlement',
  'Promisee billing period',
  'Promisor billing period',
  'Space & position',
  'Previous: {{item}}',
  'VL position',
  'Edit space and position',
  'People list',
  'Summary for analytical purposes',
  'No bank account',
  'Invoices details',
  'Project position',
  'No contract',
  'This person has an active contract only until {{date}}.',
  'This person has a contract active from {{date}}.',
  'Country of registration',
  'Headquarters address',
  'Registered in: {{country}}',
  'Custom value',
  'N/A',
  'Paid leave',
  'Entitlement (days)',
  'Enter number of days paid leave days per year.',
  'Edit paid leave',
  '0 h reported - please double check payroll input',
  'Assignment can\'t be removed because it is used in existing TR entries',
  'Select time range form',
  'Months',
  'Periods',
  'Period {{suffix}}',
  'Benefits will not be available for this contract from {{date}} ({{reason}})',
  'Benefits will be available for this contract from {{date}} ({{reason}})',
  'Benefits are not available for contracts with a basic rate in a currency other than {{currencies}}.',
  'currency change',
  'Benefits for {{currency}} are not supported',
  'Sum of total transfer amounts',
  'Sum of all gross salaries and costs',
  'Total net salary',
  'Total VAT amount',
  'Add recurring bonus',
  'Recurring bonuses',
  'One-time bonuses',
  'Drawer with a form for adding a recurring bonus',
  'Add recurring bonus',
  'Bonus description',
  'Final month of awarding',
  'Starting month of awarding',
  'starting month',
  'final month',
  'The bonus will be paid together with salaries monthly, starting with the salary for the month selected as starting.',
  'From {{startDate}}',
  'From {{startDate}} <highlight>to {{endDate}}</highlight>',
  'Recurring',
  'Next awarding: {{date}}',
  'Show more',
  'Show less',
  'Payments history',
  'Drawer with  a payment history for a given bonus',
  'Unknown bonus',
  'Recurring bonus',
  'Contract\'s termination date does not allow new bonuses of this type to be assigned',
  'Every available category for bonuses of this type has an on-going bonus with no end date or can no longer be assigned',
  'Recurring bonuses of the same category can not overlap',
  'Out of office',
  'Working days',
  'Working hours',
  'hours',
  'This field must have at least 1 value selected',
  'The time per day must be higher than 0 h.',
  'hours total',
  '({{hours}} hours left)',
  'Exceeded by {{hours}} hours',
  'Enter Working time to determine weekly hours.',
  'This field must be less than 1',
  'Edit direct reporting',
  'Drawer with a form for editing direct reporting',
  'All hours need to be used',
  'Maternity leave',
  '{{date}} - now',
  '{{project}} (internal)',
  'New paid leave entitlement cannot be lower than the number of days already used this year',
  'Paid leave entitlement cannot be removed if it already has been reported',
  'Holiday calendar',
  'You cannot set VAT rate for non-VAT payer.',
  'Open extended report',
  'Open basic report',
  'Citizenship',
  'Now',
  'Benefits assignment history',
  'No benefits history to view',
  'Last rate change (ASC)',
  'Last rate change (DESC)',
  'Create date (ASC)',
  'Reported worktime',
  'Transfer date',
  'Descending',
  'Ascending',
  'Emergency contact',
  'Remote work reimbursement',
  'Hourly rate is not editable',
  'Rate never changed',
  'Exceeds budget by {{amount}}',
  'Please select the client first',
  'Please select the project first',
  'Delete invoice {{name}}?',
  'Company role',
  'Practice lead',
  'Primary project',
  'There are no Intra-group or external subcontracts yet',
  'Add Intra-group or external subcontract',
  'Add an Intra-group or external subcontract',
  'Drawer with a form for adding an Intra-group or external subcontract',
  'Add Positions and rates',
  'net per day',
  'Positions and rates (net per day)',
  'internal subcontractor',
  'Promisor and promisee must be different',
  'Intra-group or external subcontract',
  'Add to contract',
  'Add this person to the existing subcontract',
  '<strong>{{person}}</strong> will be added to <strong>{{contract}}</strong> Intra-group or external subcontract as subcontractor.',
  '<strong>{{person}}</strong> will be added to <hidden>hidden</hidden> Intra-group or external subcontract as subcontractor.',
  'There\'s nothing here yet. Add a person to a subcontract, then assign to a project.',
  'Position assignment',
  'Subcontract pos.',
  'Project position - one of the positions defined in the client contract.',
  'Subcontract position - one of the positions defined in the contract with the subcontractor\'s company.',
  'Please create a contract first',
  'On-call rates',
  'Subcontract position',
  '{{date}} - no end date',
  'Start from {{date}}',
  'Not added to intra-contract yet',
  'Added to Intra-group or external subcontract on {{date}}',
  'Added to Intra-group or external subcontract on <hidden>hidden</hidden>',
  'Yearly cost summary',
  'Yearly cost report',
  'Drawer for yearly cost summary reports',
  'Select contract',
  'contract',
  'Summary of monthly employee costs',
  'CSV file',
  'Select date range',
  'date range',
  'Please select the contract first',
  'The employee has only one contract',
  'Sick',
  'Maternity',
  'Child care',
  'Unexpected',
  'Caregiving',
  'There\'s nothing here yet. Assign a person to a <box><project>project</project></box>.',
  'This project position is going to end on {{date}} causing this person to be unassigned from this project.',
  'Create Subcontract draft',
  'Edit Subcontract draft',
  'Rates for this position will change on {{date}}',
  'Entitlement',
  'Year',
  'Carried over',
  'By {{date}}',
  'Days to be taken by {{date}}',
  'Summary - {{year}}',
  'Drawer with annual leave summary for {{year}}',
  'You can manage annual leave entitlement values for each year on the Entitlement tab.',
  'Annual leave entitlement - {{year}}',
  'Days “From previous year” contribute to the Total Leave amount for this year.',
  'Include carry-overs in Total leave',
  'Set automatically',
  'Since {{date}}',
  'Initial',
  'Change date',
  'Set future entitlement',
  'Deduct from the transfer',
  'Benefits costs',
  'Transfer amount reduced by benefit costs',
  'Separate payment for benefits',
  'Overtime pay',
  'Cost summary',
  'Summary of monthly payroll costs for all contracts',
  'Select contracts type',
  'All contracts',
  'Employment & Civil law',
  'Date range from',
  'Date range to',
  'Time range starting month cannot go after the ending month',
  'Time range ending month cannot go before the starting month',
  'Annual leave entitlement - {{year}}',
  'Drawer with annual leave entitlement for {{year}}',
  'The entitlement was set automatically by copying the value from the previous year.',
  'You can edit entitlement for this year below.',
  'Annual leave entitlement (days)',
  'Changes effective from this year',
  'No changes to the entitlement planned for this year.',
  'Recalculate for following years',
  'Set the same entitlement value for subsequent years (until the next applied entitlement change).',
  'Change made {{date}}',
  'Note or comment…',
  'Drawer with an annual leave entitlement form',
  'Student (under 26)',
  'This contract has no Annual leave entitlement yet. Please set the new entitlement.',
  'Not set yet',
  'Delete planned change?',
  'There\'s nothing here yet',
  '0 (balance: {{days}})',
  'Annual leave preferences',
  'Drawer with a form for setting annual leave preferences',
  'Carry unused days over to next year',
  'Days “From previous year” contribute to the Total Leave amount for the year.',
  'Discard planned changes?',
  'Removing this change will merge the previous and next row. Do you want to continue?',
  'No data',
  'Assign a person to the project first',
  'Assignmen to projects',
  'Active contracts',
  'Current projects',
  'Clients',
  '{{type}} +{{others}}',
  'Properties shown in table',
  'Hidden',
  'Operational lead',
  'There is no lead assigned',
  'Document URL',
  'Authorized to process personal data',
  'Project is assumed to be primary because no other is assigned.',
  'Documents directory URL',
  'Documents directory',
  'Main directory for user\'s documents',
  'No directory set',
  'Directory URL',
  'Set directory\'s URL',
  'Summary of B2B salaries for given month',
  'B2B Salaries summary',
  'B2B salaries summary for given month',
  'Include in transfer',
  'Edit contract details',
  'Scope of work and position history',
  'Position history',
  'My subordinates',
  'Practice or Operational',
  'You don\'t own any spaces',
  'Direct subordinates',
  'Position rates history',
  'Active positions',
  'Ended positions',
  'Future positions',
  'Due dates of student statuses, occupational medicine and others',
  'Employee reminders',
  'Due dates of student statuses, occupational safety and health training and occupational medicine',
  'Next BHP training',
  'Next medical examination',
  'Student status revision',
  'Next medical examination date (default)',
  'Edit user\'s dates',
  'Next OHS training',
  'Next OHS training date',
  'Drawer with a form for editing user\'s dates',
  'Any revision date',
  'Any revision date (default)',
  'Project description',
  'Project time',
  'Client info',
  'Live',
  '{{endDate}}',
  'From {{startDate}} to',
  'Closed',
  'Onhold',
  'From {{startDate}} to {{endDate}}',
  'No members',
  'No lead assigned',
  'Lead: {{user}}',
  'Add new member',
  'No space',
  'Start year',
  'This client contract\'s project is not active yet',
  'This client contract\'s project has no assigned members',
  'This client contract has no positions',
  'This client contract has no assigned project',
  'Project details',
  'Go to the list of projects',
  'About project',
  'On-hold',
  'Commercial project',
  'Internal project',
  'Project type',
  'Project description',
  'Project leader',
  'Add a project leader',
  'Rate card (man-day)',
  'This project is scheduled to end on {{date}}',
  'This project is closed since {{date}}',
  'This project is on hold from {{startDate}} to {{endDate}}',
  'This project is planned and starts on {{date}}',
  'To assign one, mark one of the team members as a project leader.',
  'The project has no leader yet. ',
  'The current project leader will be in this position until {{date}}. You can plan another team member for that position after that date.',
  'Project has no members',
  'Active',
  'Project members',
  'There are no project members yet',
  'Future',
  'You can\'t change the client for an active project',
  'Put project on hold',
  'Final transfer details',
  'Transfer deduction',
  'Deduction',
  'Add a note',
  'transfer amount - transfer deduction',
  'invoice amount - luminis value - transfer deduction',
  '{{invoice}} - {{luminis}} - {{transfer}}',
  '{{invoice}} - {{transfer}}',
  'Past project members',
  'You can\'t change the company for an active project',
  'Transfer amount reduced by benefit costs {{amount}}',
  'Report range',
  'Custom range',
  'Standard range',
  'See salaries range',
] as const;

export default messages;
