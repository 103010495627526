import type { AnyRoutesConfig } from '@virtuslab/nfs-shared/src/services/hooks/useRoutes';
import { emptyRoutesConfig } from '@virtuslab/nfs-shared/src/services/hooks/useRoutes';
import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';
import { mergeConfigs } from '@virtuslab/nfs-shared/src/services/routes';

import SecureRoutesWrapper from '../components/organisms/SecureRoutesWrapper';
import login from '../components/pages/AuthLogin/config';
import logout from '../components/pages/AuthLogout/config';
import benefits from '../components/pages/Benefits/config';
import companies from '../components/pages/Companies/config';
import companyDetails from '../components/pages/CompanyDetails/config';
import contracts from '../components/pages/Contracts/config';
import dashboard from '../components/pages/Dashboard/config';
import error404 from '../components/pages/Error404/config';
import people from '../components/pages/People/config';
import personDetails from '../components/pages/PersonDetails/config';
import projectDetails from '../components/pages/ProjectDetails/config';
import projects from '../components/pages/Projects/config';
import reports from '../components/pages/Reports/config';
import salaries from '../components/pages/Salaries/config';
import salariesDetails from '../components/pages/SalariesDetails/config';
import spaceDetails from '../components/pages/SpaceDetails/config';
import spaces from '../components/pages/Spaces/config';

import { BaseRoutes } from './paths';

const defaultSecureConfig: RoutesConfig<WrapRoutes<typeof BaseRoutes>> = {
  paths: {
    [BaseRoutes.BASE]: {
      render: SecureRoutesWrapper,
    },
  },
};

const secureRoutes: AnyRoutesConfig = [
  defaultSecureConfig,
  people,
  personDetails,
  contracts,
  companies,
  companyDetails,
  projects,
  projectDetails,
  benefits,
  reports,
  spaces,
  spaceDetails,
  dashboard,
  salaries,
  salariesDetails,
  error404,
].reduce(mergeConfigs, emptyRoutesConfig);

const insecureRoutes: AnyRoutesConfig = [
  login,
  logout,
].reduce(mergeConfigs, emptyRoutesConfig);

const config = mergeConfigs(insecureRoutes, secureRoutes);
export default config;
