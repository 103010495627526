import type { ReactElement, ReactNode } from 'react';
import {
  createContext, useContext, useEffect, useState,
} from 'react';

import { useAuth } from '@virtuslab/react-oauth2';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import Loading from '@virtuslab/nfs-shared/src/components/molecules/Loading';
import { useAccessRightsProviderLazyQuery, useAccessRightsProviderQuery } from '@virtuslab/nfs-shared/src/schema/admin';
import { hasAccess as unscopedHasAccess } from '@virtuslab/nfs-shared/src/schema/admin/roleMap';
import { isEmpty } from '@virtuslab/nfs-shared/src/services/checks';
import { getData } from '@virtuslab/nfs-shared/src/services/errors';
import { isArrayOf, isNotNil, isObjectWithKeys } from '@virtuslab/nfs-shared/src/services/guards';
import type { Nullable } from '@virtuslab/nfs-shared/src/services/object';

import { pageEntryQueries } from '../../../config/navigation';
import {
  BenefitsRoutes,
  CompaniesRoutes,
  CompanyDetailsRoutes,
  ContractsRoutes,
  ProjectsRoutes,
  ReportsRoutes,
  SalariesDetailsRoutes,
  SalariesRoutes,
  SpacesRoutes,
  type RouteAccessChecker,
} from '../../../config/paths';

const parseJwt = (token: string): unknown => {
  const [, payload] = token.split('.');
  const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64Payload).split('').map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Hook = (...args: any[]) => any;

export type HookAccessConfig = (Hook | { or: Hook[] })[];
export type Self = Readonly<{
  id: string;
  ownedSpaces: string[];
}>;

type ContextValue = Readonly<{
  hasAccess: (hooks: HookAccessConfig) => boolean;
  routeAccessChecker: RouteAccessChecker;
  self: Self;
}>;

export const context = createContext<Nullable<ContextValue>>(null);

export const associatedQueries = [
  useAccessRightsProviderQuery,
];

type Props = Readonly<{
  children: ReactNode;
}>;

const AccessRightsProvider = ({ children }: Props): ReactElement => {
  const auth = useAuth();
  const profile = auth.getUserProfile();

  const fullName = `${profile.firstName ?? ''} ${profile.lastName ?? ''}`;

  const [load] = useAccessRightsProviderLazyQuery({
    variables: {
      email: profile.email ?? '',
      fullName: `${profile.firstName ?? ''} ${profile.lastName ?? ''}`,
    },
  });

  const [loading, setLoading] = useState(true);
  const [contextValue, setContextValue] = useState<ContextValue>({
    hasAccess: () => true,
    routeAccessChecker: () => true,
    self: {
      id: '',
      ownedSpaces: [],
    },
  });

  useEffect(() => {
    const wrapper = async () => {
      const userProfile = await auth.getAccessToken()
        .then((token) => (isNil(token) ? null : parseJwt(token)));

      if (!isObjectWithKeys(userProfile, ['realm_access']) || !isObjectWithKeys(userProfile.realm_access, ['roles'])) {
        return null;
      }

      if (!isObjectWithKeys(userProfile, ['preferred_username']) || !isString(userProfile.preferred_username)) {
        return null;
      }

      const { data } = await load({
        variables: {
          fullName,
          email: userProfile.preferred_username,
        },
      });

      if (isNil(data)) {
        return null;
      }

      const roles = isArrayOf(userProfile.realm_access.roles, isString) ? userProfile.realm_access.roles : [];
      setContextValue(() => {
        const hasAccess: ContextValue['hasAccess'] = (hooks) => {
          if (isEmpty(hooks)) {
            return true;
          }

          const allRoles = [
            ...roles,
            ...getData(data.whoAmI)?.value ?? [],
          ].filter(isNotNil);

          return hooks.every((hook) => {
            if (isObjectWithKeys(hook, ['or'])) {
              return hook.or.some((disjunctiveHook) => unscopedHasAccess(allRoles, disjunctiveHook));
            }

            return unscopedHasAccess(allRoles, hook);
          });
        };

        return {
          hasAccess,
          self: {
            id: first(getData(data.users)?.edges)?.node.id ?? '',
            ownedSpaces: (getData(data.spaces)?.edges ?? []).map((space) => space.node.id),
          },
          routeAccessChecker: (root) => {
            if (!hasAccess(pageEntryQueries.benefits)
                      && root === BenefitsRoutes) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.companies)
                    && (root === CompaniesRoutes || root === CompanyDetailsRoutes)) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.salaries)
                  && (root === SalariesRoutes || root === SalariesDetailsRoutes)) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.contracts)
                      && (root === ContractsRoutes)) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.projects)
                      && (root === ProjectsRoutes)) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.spaces)
                      && (root === SpacesRoutes)) {
              return false;
            }

            if (!hasAccess(pageEntryQueries.reports)
                        && (root === ReportsRoutes)) {
              return false;
            }

            return true;
          },
        };
      });

      setLoading(false);

      return null;
    };

    wrapper();
  }, [load, auth, fullName]);

  return (
    <context.Provider value={contextValue}>
      {loading ? <Loading /> : children}
    </context.Provider>
  );
};

export const useCheckAccessRights = (): ContextValue => {
  const value = useContext(context);

  if (isNil(value)) {
    throw Error('Used useCheckAccessRights outside of context');
  }

  return value;
};

export default AccessRightsProvider;
